<template>
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6 mb-3">
            <div class="card main-card">
                <div class="card-body">
                    <v-carousel :cycle="false" hide-delimiters height="auto">
                        <v-carousel-item
                            v-for="(img, i) in house.imgs"
                            :key="i"
                            :src="img.src"
                        ></v-carousel-item>
                    </v-carousel>
                    <div class="container">
                        <div class="d-flex flex-row">
                        </div>
                        <p class="h5">
                            Holiday house <b>{{ this.house.name }}</b> <span v-if="this.house.name == 'Harmony'">(7 guests)</span><span v-else>(5 guests)</span>
                        </p>
                        <ul class="list-group" style="border: none">
                            <li style="border: none" class="list-group-item justify-content-between align-items-center pt-1 pb-1 pl-2" v-for="(ft, i) in house.features" :key="i">
                                <span>
                                    <span v-if="!ft.included">{{ ft.name }}</span>
                                    <span v-else>{{ ft.name }}</span>
                                </span>
                            </li>   
                        </ul>
                        <v-divider>
                        </v-divider>
                        <div class="d-flex" v-if="bookedDates">
                            <house-reservation-modal class="w-50" :house="house" :vouchers="vouchers" :selection="selected" :available="available" :bookedDates="bookedDates"></house-reservation-modal>
                            <house-confirmation-modal  :status="status" :fullName="fullName" :details="reservationDetails"></house-confirmation-modal>
                            <div class="d-flex flex-column justify-content-end">
                                <p class="text-right">100€ / night</p>
                                <p class="text-right">90€ / night for 3+ nights</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<script>
import HouseReservationModal from '../modals/HouseReservationModal.vue'
import HouseConfirmationModal from '../modals/HouseConfirmationModal.vue'
import {mapGetters} from 'vuex'
export default {
    name: "HouseBox",
    components: {
        HouseReservationModal,
        HouseConfirmationModal
    },
    props: [
        "house",
        "vouchers",
        "selected"
    ],
    data() {
        return {
            page: null,
            status: {ok: true},
            reservationDetails: {
                checkIn: new Date(2021, 11, 24),
                checkOut: new Date(2021, 11, 27),
                house: this.house,
                price: 100,
                priceHTL: 100 / this.htlEur,
                voucher: null,
            },
            bookedDates: null
        }
    },
    computed: {
        ...mapGetters({
          available: 'user/available',
          fullName: 'user/fullName',
          htlEur: 'prices/htlEur',
        }),
    },
    methods: {
        getBookedDates() {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/vse_rezervacije_nepremicnine2?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                nepremicnina: this.house.id
            }).then((res) => {
                var tmp
                tmp = res.data.map(d => {
                    return new Date(d)
                })
                tmp = tmp
                .map(function (date) { return date.getTime() })
                .filter(function (date, i, array) {
                    return array.indexOf(date) === i;
                })
                .map(function (time) { return new Date(time); });
                this.bookedDates = tmp.sort((a, b) => {
                    return new Date(a.getTime()) - new Date(b.getTime())
                })
            })
        }
    },
    watch: {
        reservationDetails: function() {
            this.reservationDetails.priceHTL = (this.reservationDetails.price / this.htlEur).toFixed(2)
        },
        htlEur: function() {
            this.reservationDetails.priceHTL = (this.reservationDetails.price / this.htlEur).toFixed(2)
        },
        vouchers: function() {
            this.reservationDetails.voucher = this.vouchers[0]
        }
    },
    created() {
       // this.getBookedDates()
        //this.getBookedDates()
    }
}
</script>
<style scoped>
</style>