<template>
    <div>
        <v-dialog
        v-model="dialog" width="500" persistent>

                    <v-card v-if="status">
                        <v-card-title class="headline grey lighten-2">
                            <span v-if="getStatus">Reservation confirmation for <b>{{ details.house.name }}</b></span>   
                            <span v-else>An error has occured</span>
                        </v-card-title>
                        <v-card-text>

                            <div class="row">
                                <div class="col-6 text-left h6">Check-in:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">{{ details.checkIn|formatDateShort }}</p></div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-left h6">Check-out:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">{{ details.checkOut|formatDateShort }}</p></div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6 text-left h6">Price for {{ getNumDays }} night<span v-if="getNumDays > 1">s</span>:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">{{ details.price }}€</p></div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-left h6">Voucher:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">-{{ (details.voucher) ? details.voucher.vrednost : 0 }}€</p></div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-left h6">Amount to be paid:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">{{ getOwedAmount }}€</p></div>
                            </div>
                            <div class="row">
                                <div class="col-6 text-left h6">Security cost:</div>
                                <div class="col-6 d-flex"><p class="text-right h6">{{ (30 / htlEur).toFixed(2) }} HTL</p></div>
                            </div>
                            <hr>
                            <div class="d-flex">
                                <div class="d-flex flex-column col-6">
                                    <v-radio-group style="width: 100%" v-model="radioGroup">
                                            <v-radio
                                                key="htl"
                                                label="Use HTL from your available funds"
                                                color="primary"
                                                value="htl"
                                                active
                                            ></v-radio>
                                        <v-radio
                                            key="eur"
                                            label="Pay with cash on arrival"
                                            color="primary"
                                            value="eur"
                                        ></v-radio>
                                        </v-radio-group>
                                </div>
                                <div class="d-flex flex-column justify-content-around text-right p-4">
                                    <h6>{{ finalPriceToHTL }} + {{ (30 / htlEur).toFixed(2) }} HTL</h6>
                                    <h6 v-if="details.voucher">{{ getOwedAmount }}€ + {{ (30 / htlEur).toFixed(2) }} HTL</h6>
                                </div>
                            </div>
                            <div class="row">
                                You will get the booking details in your email.<br>
                                We wish you a pleasant stay
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
                            <v-btn color="primary" text @click="confirmReservation()" :disabled="buttonDisabled">Confirm reservation</v-btn>
                        </v-card-actions>
                    </v-card>
        </v-dialog>
    </div>
</template>
<script>
import * as moment from "moment";
import {mapGetters} from "vuex";
export default {
    name: "HouseConfirmationModal",
    props: [
        'status',
        'fullName',
        'details'
    ],
    data() {
        return {
            dialog: false,
            radioGroup: "htl",
            reqStatus: null
        }
    },
    computed: {
        getStatus() {
            return this.status ? this.status.ok : false
        },
        buttonDisabled() {
            return (this.available / 10e7 < (this.details.priceHTL + (30 / this.htlEur)) && this.radioGroup == 'htl') || this.available / 10e7 < (30 / this.htlEur) 
        },
        getOwedAmount() {
            return (this.details.voucher) ? Math.max(this.details.price - this.details.voucher.vrednost, this.details.price / 2) : this.details.price
        },
        ...mapGetters({
            available: 'user/available',
            htlEur: 'prices/htlEur',
        }),
        getNumDays() {
            return Math.abs(moment(this.details.checkIn).diff(moment(this.details.checkOut), 'days'))
        },
        finalPriceToHTL() {
            return (this.getOwedAmount / this.htlEur).toFixed(2)
        }
    },
    methods: {
        confirmReservation() {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/rezerviraj?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token')), {
                vavcer: this.details.voucher._id,
                vrednostRezervacije: this.getOwedAmount,
                nepremicnina: (this.details.house.name == 'Harmony') ? 1 : 2,
                zacetek: moment(this.details.checkIn).format('YYYY-MM-DD'),
                konec: moment(this.details.checkOut).format('YYYY-MM-DD'),
                placiloGotovina: (this.radioGroup == "htl") ? false : true 
            }).then((res) => {
                this.$parent.$parent.fetchVouchers()
                if(res.data.success) {
                    alert("Reservation created successfully. \nYou can check your reservations under My Wallet tab.")
                }
                else if(res.data.status == "error") {
                    alert("Reservation request failed")
                }
                this.dialog = false
                this.$emit('updateDates')
            });
        }
    },
    watch: {
        status: function() {
            this.dialog = true

        }
    }
}
</script>