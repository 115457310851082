<template>
    <div>
        <v-dialog
        v-model="dialog" width="700" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="!vouchers">
                    BOOK NOW
                </v-btn>
            </template>
                    <v-card  v-if="vouchers">
                        <v-card-title class="headline grey lighten-2">
                            Select dates for &nbsp; <b>{{ house.name }}</b>
                        </v-card-title>
                        <v-card-text>
                            <div class="row">
                                <div class="col-12 justify-content-center">
                                    <div class="w-100" style="text-align: justify !important">
                                        <div class="col-12 ml-3 mb-3 text-muted">
                                        Terms
                                            <ol>
                                                <li>With a voucher you pay a maximum of 50% of the final amount. You can use a voucher only in one piece.</li>
                                                <li>In order to complete the booking you must have enough available HTL tokens in your account.</li>
                                                <li>The difference from the voucher to the full amount can also be settled with cash on the spot.</li>
                                                <li>Tourist tax of 1 € per person per night is collected in cash on the spot.</li>
                                                <li>The reservation can be canceled up to 14 days before the date of arrival, with a full refund of both the voucher and HTL tokens.</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="input-group input-group-lg">
                                <div class="input-group-prepend" style="max-width: 25%">
                                    <span class="input-group-text">Select voucher</span>
                                </div>
                                <select class="custom-select" v-model="selected">
                                    <option v-for="(voucher, index) in vouchers" :key="index" v-bind:value="voucher">{{ voucher.ime }}</option>
                                </select>
                            </div>
                            <br>
                        </v-card-text>
                        <div class="container pt-0">
                            <div class="row">

                                <div class="col-12">
                                    <hotel-date-picker @check-in-changed="checkIn" @check-out-changed="checkOut" v-if="!isMobile" :gridStyle="false" :firstDayOfWeek="1" :alwaysVisible="true" :disableCheckoutOnCheckin="true" :disabledDates="disabledDates"></hotel-date-picker>
                                    <hotel-date-picker v-else :showSingleMonth="true" :gridStyle="false" :firstDayOfWeek="1" :disableCheckoutOnCheckin="true" :disabledDates="disabledDates"></hotel-date-picker>
                                </div>
                            </div>
                        </div>
                        <v-card-actions>
                            <span class="ml-3 w-50 text-dark "><h6 class="d-inline-block font-weight-bold">Price: &nbsp; {{ getPrice + 30 }}€</h6></span>
                            <v-spacer></v-spacer>

                            <v-btn color="primary" text @click="dialog = false">Close</v-btn>
                            <v-btn color="primary" text @click="confirmReservation()" :disabled="buttonDisabled" v-if="checkBalance()">Continue</v-btn>
                            <v-btn color="primary" text @click="buyHTL()" :disabled="buttonDisabled" v-else>Buy HTL</v-btn>
                        </v-card-actions>
                    </v-card>
        </v-dialog>
    </div>
</template>
<script>
import HotelDatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import * as moment from "moment";

export default {
    name: "HouseReservationModal",
    props: [
        "house",
        "vouchers",
        "selection",
        "available",
        "bookedDates"
    ],
    components: {
        HotelDatePicker
    },
    data() {
        return {
            dialog: false,
            selected: (this.vouchers) ? this.vouchers[0] : null,
            date1: new Date(),
            date2: new Date(2021, 11, 30),
            cash: false,
        }
    },
    methods: {
        excludedArrivalDates(date) {
            var booked = this.bookedDates.find(val => {
                var lol = new Date(val)

                return  lol.getDate() == date.getDate() && lol.getMonth() == date.getMonth() && lol.getYear() == date.getYear()
            })

            return booked || date < new Date()
        },
        excludedDepartureDates(date) {
            var latestPossible = (this.date1) ? this.bookedDates.find(val => {
                return val.setHours(0, 0, 0, 0) >= this.date1
            }) : null

            var booked = this.bookedDates.find(val => {
                var lol = new Date(val)
                return lol.getDate() == date.getDate() && lol.getMonth() == date.getMonth() && lol.getYear() == date.getYear()
            })
            return this.date1 ? date <= this.date1 || date >= latestPossible: booked || date < new Date()
        },
        confirmReservation() {
            this.$parent.status = {ok: true}
            var resDetails = {
                checkIn: this.date1,
                checkOut: this.date2,
                house: this.house,
                price: this.getPrice,
                voucher: this.selected,
                cash: this.cash
                }
            this.$parent.reservationDetails = resDetails
            this.dialog = false
        },
        buyHTL() {

        },
        checkBalance() {
          
            return true 
        },
        checkIn(val) {
            console.log(val)
            this.date1 = val;
        },
        checkOut(val) {
            console.log(val)
            this.date2 = val
        }

    },
    computed: {
        dateRangeText() {
            return this.dates.join('~')
        },
        buttonDisabled() {
            return !(this.date1 && this.date2)
        },
        placeholderDate() {
            return this.date1 ? this.date1 : Date.now() 
        },
        getPrice() {
            return (this.date1 && this.date2) ? (moment(this.date2).diff(moment(this.date1), 'days') > 2) ? 
                moment(this.date2).diff(moment(this.date1), 'days') * (this.house.price - 10) : moment(this.date2).diff(moment(this.date1), 'days') * this.house.price : 0
        },
        isMobile() {
            var lmao = window.matchMedia('(max-width: 991px)').matches
            console.log(lmao);
            console.log();
            return window.matchMedia('(max-width: 576px)').matches;
        },
        disabledDates() {
            var tmp = this.bookedDates.map(val => {
                return moment(val).format('YYYY-MM-DD')
            })
            console.log("Booked dates " + this.house.name)
            console.log(tmp)
            return tmp
        }
    },
    watch: {
        dialog: function() {
            this.date1 =  null
            this.date2 = null
        },
        vouchers: function() {
            this.selected =  this.vouchers[0]
        }
    },
    created() {
    }
}
</script>
<style>


    @media only screen and (max-width: 576px) {
        .mx-datepicker-main {
            position: absolute !important;
            left: 0px !important;
            margin: auto;
            width: 100%
        }
        .mx-datepicker-body  .mx-calendar-content{
            width: 100% !important
        }
        .mx-calendar {
            width: 100% !important
        }
    }

    .cell.disabled {
        background-color: rgba(255, 100, 100, 0.5) !important;
        color: rgba(255, 0, 0, 0.5) !important
    }
    .cell {
        background-color: rgba(0, 224, 20, 0.5) !important;
        color: rgba(0, 224, 0, 0.5) !important;
        font-weight: bold !important
    }

    .vhd__datepicker__month-day--halfCheckIn {
        background: linear-gradient(-45deg, rgba(252, 187, 171, 1) 0%, rgba(252, 187, 171, 1) 50%, transparent 26%);
        background-color: rgba(198, 241, 201, 1);
        font-weight: bold
    }

    .vhd__datepicker__month-day--halfCheckIn .checkOut {
        background: none !important;
    }

    .vhd__datepicker__month-day--last-day-selected {
        background: none;
    }

    .vhd__datepicker__month-day--halfCheckIn span {
        background: transparent !important
    }

    .vhd__datepicker__month-day--halfCheckOut {
        background: linear-gradient(135deg, rgba(252, 187, 171, 1) 0%, rgba(252, 187, 171, 1) 50%, transparent 26%);
    }

    .vhd__datepicker__month-day--disabled {
        background-color: rgba(252, 187, 171, 1);
        color: #000;
        
    }

    .vhd__datepicker__month-day--valid {
        background-color: rgba(198, 241, 201, 1)
    }

    .vhd__datepicker__month-day--today .vhd__datepicker__month-day-wrapper {
        border-color: #2fa4e7 !important;
    }

    .vhd__datepicker__month-day--selected {
        background-color: rgba(47, 164, 231, 0.7);
    }

    .vhd__datepicker__month-day--first-day-selected, .vhd__datepicker__month-day--last-day-selected, .vhd__datepicker__month-day--selected:hover, .vhd__datepicker__month-day--valid:hover {
        background-color: rgba(47, 164, 231, 1) !important;
    }

    .vhd__datepicker__month-day--last-day-selected > vhd__datepicker__month-day-wrapper {
        background: transparent
    }

    .vhd__datepicker__month-button {
        border-color: rgba(47, 164, 231, 1);
        color: rgba(47, 164, 231, 1) !important;   
    }

    .vhd__datepicker__month-day--disabled {
        font-weight: bold
    }

</style>