<template>
    <div>
        <h2>Welcome to <b>{{$t('menu-diamond-club')}}</b></h2>
        <div class="row mt-3">
            <div class="col-12 mb-3">
                <div class="w-100 d-flex" v-if="!isMobile">
                    <v-carousel :cycle="true" hide-controls hide-delimiter-background height="auto">
                    <v-carousel-item
                        v-for="(img, i) in desk1"
                        :key="i"
                        :src="img.src"
                    ></v-carousel-item>
                    </v-carousel>
                    <v-carousel :cycle="true" hide-controls hide-delimiter-background height="auto">
                    <v-carousel-item
                        v-for="(img, i) in desk2"
                        :key="i"
                        :src="img.src"
                    ></v-carousel-item>
                    </v-carousel>
                </div>
                <div class="w-100" v-else>
                    <v-carousel :cycle="true" hide-controls hide-delimiter-background height="auto">
                    <v-carousel-item
                        v-for="(img, i) in headingImages"
                        :key="i"
                        :src="img.src"
                    ></v-carousel-item>
                    </v-carousel>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 h-50 d-flex flex-column">
                <p class="h6">The beautiful mountain landscape of Zlatibor in Serbia is an attractive tourist center. In summer, it invites visitors with hiking trails, bike paths, golf courses, adrenaline parks, and swimming pools, and when the snow falls it becomes a great center for winter sports. In addition, the place boasts the longest panoramic gondola in the world. </p>
            </div>
            <div class="col-12 d-flex flex-column">
                <p class="h6 mb-3">
                    Diamond Club Zlatibor consists of two standalone holiday houses, with 70 square meters of living space each suitable for up to 5 or 7 visitors. They are all fully equipped with modern and quality furniture, <b>including a proper fireplace, washing machine, television, tableware, bed linen, and towels. There is also a terrace and parking space.</b>
                </p>
                <p class="h6">
                    <b>As a loyal member, you benefit from a lower price, the option to use a voucher, and payment with available HTL.</b>
                </p>
            </div>
        </div>
        <div class="row mb-3 justify-content-center">
            <div class="d-flex flex-row flex-wrap w-100"  v-if="houses">
                    <house-box v-for="house in houses" :key="house.name" :house="house" :vouchers="vouchers" :selected="selected" />
            </div>
            <div v-else>
                <div class="font-icon-wrapper float-left mr-3 mb-3">
                    <div class="loader-wrapper d-flex justify-content-center align-items-center">
                        <pacman-loader color="primary"></pacman-loader>
                    </div>
                    <p></p>
                </div>
            </div>
        </div>
        <div class="row text-muted">
            <span class="ml-3">Terms and conditions</span>
            <div class="col-12 ml-3 mb-3">
                <ol>
                    <li>With a voucher you pay a maximum of 50% of the final amount. You can use a voucher only in one piece.</li>
                    <li>In order to complete the booking you must have enough available HTL tokens in your account.</li>
                    <li>The difference from the voucher to the full amount can also be settled with cash on the spot.</li>
                    <li>Tourist tax of 1 € per person per night is collected in cash on the spot.</li>
                    <li>The reservation can be canceled up to 14 days before the date of arrival, with a full refund of both the voucher and HTL tokens.</li>
                </ol>
            </div>
        </div>
    </div>
</template>

<script>
import PacmanLoader from "vue-spinner/src/PacmanLoader.vue";
import HouseBox from "../components/Packages/HouseBox";

export default {
    name: "DiamondClubPage",
    props: {
        voucher: {
            default: "default"
        }
    },
    components: {
        PacmanLoader,
        HouseBox,
    },
    data() {
        return {
            page: null,
            headingImages: [
                { src: require('@/assets/images/zlatibor/1.jpg') },
                { src: require('@/assets/images/zlatibor/2.jpg') },
                { src: require('@/assets/images/zlatibor/3.jpg') },
                { src: require('@/assets/images/zlatibor/4.jpg') },
                { src: require('@/assets/images/zlatibor/5.jpg') },
                { src: require('@/assets/images/zlatibor/6.jpg') },
                { src: require('@/assets/images/zlatibor/7.jpg') },
                { src: require('@/assets/images/zlatibor/8.jpg') },
            ],
            desk1: [
                { src: require('@/assets/images/zlatibor/5.jpg') },
                { src: require('@/assets/images/zlatibor/6.jpg') },
                { src: require('@/assets/images/zlatibor/7.jpg') },
                { src: require('@/assets/images/zlatibor/8.jpg') },
            ],
            desk2: [
                { src: require('@/assets/images/zlatibor/1.jpg') },
                { src: require('@/assets/images/zlatibor/2.jpg') },
                { src: require('@/assets/images/zlatibor/3.jpg') },
                { src: require('@/assets/images/zlatibor/4.jpg') },
            ],
            houses: [
                {
                    id: "1",
                    name: "Harmony",
                    features: [
                        {name: "3 bedrooms (double bed / 4 single beds / sofa)", included: true},
                        {name: "1 bathroom / kitchen / living room / eating area", included: true},
                    ],
                    imgs: [ 
                        { src: require('@/assets/images/zlatibor/harmony/i1.jpg') },
                        { src: require('@/assets/images/zlatibor/harmony/i2.jpg') },
                        { src: require('@/assets/images/zlatibor/harmony/i3.jpg') },
                        { src: require('@/assets/images/zlatibor/harmony/i4.jpg') },
                        { src: require('@/assets/images/zlatibor/harmony/i5.jpg') },
                    ],
                    price: 100
                },
                // {
                //     id: "2",
                //     name: "Simphony",
                //     features: [
                //         {name: "2 bedrooms (double bed / 2 single beds / sofa)", included: true},
                //         {name: "2 bathroom / Kitchen / Living room / Eating area", included: true},
                //     ],                    
                //     imgs: [ 
                //         { src: require('@/assets/images/zlatibor/abundance/i1.jpg') },
                //         { src: require('@/assets/images/zlatibor/abundance/i2.jpg') },
                //         { src: require('@/assets/images/zlatibor/abundance/i3.jpg') },
                //         { src: require('@/assets/images/zlatibor/abundance/i4.jpg') },
                //         { src: require('@/assets/images/zlatibor/abundance/i5.jpg') },
                //     ],
                //     price: 100
                // },
                {
                    id: "2",
                    name: "Melody",
                    features: [
                        {name: "2 bedrooms (double bed / 2 single beds / sofa)", included: true},
                        {name: "2 bathrooms / kitchen / living room / eating area / sauna", included: true},
                    ],                    
                    imgs: [ 
                        { src: require('@/assets/images/zlatibor/melody/a.jpg') },
                        { src: require('@/assets/images/zlatibor/melody/b.jpg') },
                        { src: require('@/assets/images/zlatibor/melody/c.jpg') },
                        { src: require('@/assets/images/zlatibor/melody/d.jpg') },
                        { src: require('@/assets/images/zlatibor/melody/e.jpg') },
                    ],
                    price: 100
                },
            ],
            vouchers: null,
            selected: null,
            reqStatus: null
        }
    },
    methods: {    
        fetchVouchers() {
            this.axios.post(this.baseURL+'/admin-api/v0/bc/vavcerji?access_token=' + ((sessionStorage.getItem('apollo-token')) ? sessionStorage.getItem('apollo-token') : localStorage.getItem('apollo-token'))).then(response => {
                this.vouchers = response.data.filter(voucher => !voucher.porabljen)
                this.vouchers.unshift({
                    _id: 0,
                    ime: "No voucher",
                    porabljen: null,
                    vrednost: 0,
                })
                for(var i = 0; i < this.vouchers.length; i++) {
                    if(this.vouchers[i].name == this.voucher.name) {
                        this.selected = this.vouchers[i]
                    }
                }   
                if(!this.selected)
                    this.selected = this.vouchers[1]     
            })
        },
        getImgs() {
            console.log(this.headingImages.splice(0, 3))
        }
    },
    computed: {
        isMobile() {
            var lmao = window.matchMedia('(max-width: 991px)').matches
            console.log("Mobile: ");
            console.log(lmao);
            return window.matchMedia('(max-width: 991px)').matches;
        }
    },
    created() {
        this.fetchVouchers()
    }
}
</script>
<style>
    .v-carousel__controls {
        background: rgba(0, 0, 0, 0)
    }
</style>